import { Component, OnInit, Input } from '@angular/core';
import { ModsService } from '../../services/mods.service';
import { UserService } from 'src/app/services/user.service';
import { ToolsService } from 'src/app/services/tools.service';

@Component({
  selector: 'app-alert',
  templateUrl: './alert.component.html',
  styleUrls: ['./alert.component.css']
})

export class AlertComponent implements OnInit {

  @Input() public alert:any;

  username:any="";
  password:any="";
  obs:any="";

  constructor( private mods:ModsService, private user:UserService, private tools:ToolsService ) { }

  ngOnInit(): void {
  }

  cancel(reason){
    this.mods.updAlert({status:false,click:true})
  }

  confirm(reason){
    this.mods.updAlert({status:false,click:true,alertType:'confirm',confirm: reason})
  }

  login(){
    this.alert.status=false;
    console.log(this.user.getUser());
    let status = this.user.relogin(this.username,this.password)
    console.log(this.alert.pageType,this.alert[status?'funcSuccess':'funcFail']);
    this.tools.cmdFunc(this.alert.pageType,this.alert[status?'funcSuccess':'funcFail'],this.obs);
  }

}
