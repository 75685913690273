import { Injectable } from '@angular/core';
import * as CryptoJS from 'crypto-js';
import { ApiService } from './api.service';
import { SyncService } from './sync.service';
import { ModsService } from './mods.service';
import { LangService } from './lang.service';
import { Socket } from 'socket.io-client';

declare var XLSX: any;
declare var X2JS: any;
declare var saveAs: any;
declare var jspdf: any;

@Injectable({
  providedIn: 'root'
})

export class ToolsService {

  key: string = "BEWAREOBLIVIONISATHAND"; //length=23
  tokenSaveTime: number;
  tokenSaveId: String;
  tokenSave: String;

  sessionTime: any

  utils: any = {
    genIV: () => {
      return Math.random().toString(36).substr(2, 30);
    },
    encrypt: (text, iv) => {
      let base = CryptoJS.AES.encrypt(text, this.key, { iv: iv });
      return base.toString();
    },
    decrypt: (text, iv) => {
      let data = CryptoJS.AES.decrypt(text, this.key, { iv: iv }).toString(CryptoJS.enc.Utf8);
      return data;
    },
    protect: (data) => {
      let iv = this.utils.genIV();
      return { cipher: this.utils.encrypt(JSON.stringify(data), iv), iv: iv, protect: true, app_key: '4vxaC8b019x6O3J9YJ3Q3qrr', app_secret: 'GKea2bC1UR8w' }
    },
    extract: (data) => {
      return JSON.parse(this.utils.decrypt(data.cipher, data.iv));
    }
  }

  userInfo: any = {}
  tools: any = {
    layer: {},
    modal: {}
  };

  constructor(
    private api: ApiService,
    private lang: LangService,
    public mods: ModsService,
    private sync: SyncService
  ) { }

  async startTools(layout, optItem, callback) {
    let active = this.mods.getActive();

    let findRecursive = (list, field, value, sublists) => list.concat(sublists.map(sublist => list.filter(e => e[sublist] && e[sublist].length > 0).map(e => e[sublist]).reduce((n, p) => n.concat(p), [])).reduce((n, p) => n.concat(p), [])).find(e => e[field] == value)
    layout.scope.item = await this.loadItem(optItem, (layout.scope.item || {}))
    var itemNew = true
    if (layout.scope.item._id) {
      itemNew = false
    }

    let toolsBase = {
      crypto: CryptoJS,
      translate: (value, scope) => this.nameParse(value, value, scope),
      shortcut: (data) => this.shortcut(data),
      excel: (data, cols, ReportTitle, callback) => this.excel(data, cols, ReportTitle, callback),
      excelAsync: (data, cols, ReportTitle) => new Promise(resolve => this.excel(data, cols, ReportTitle, result => resolve(result))),
      pdf: (rows, cols, title, callback) => this.pdf(rows, cols, title, callback),
      pdfAsync: (rows, cols, title, callback) => new Promise(resolve => this.pdf(rows, cols, title, result => resolve(result))),
      alert: (data, callback) => this.alert(data, callback),
      alertAsync: (data) => new Promise(resolve => this.alert(data, result => resolve(result))),
      layout: (data) => this.layout(data),
      socket: this.sync.getSocket(),
      close: () => this.mods.updComponents({ type: 'exitModal' }),
      upd: (pageType) => this.mods.updComponents({ pageType: pageType, type: 'scope' }),
      setLoad: (pageType, table, list) => this.setLoad(pageType, table, list),
      setItem: (pageType, scope, model, value) => this.setItem(pageType, scope, model, value),
      dataForm: (lista) => this.dataForm(lista),
      itemObj: (item) => this.itemObj(item),
      sleep: ms => new Promise(r => setTimeout(r, ms)),
      cmdFunc: (pageType, funcname, params?) => this.cmdFunc(pageType, funcname, params),
      loading: (value, msg?) => this.mods.updAlert({ type: 'loading', msg: msg, value: value, lock: true }),
      getUserInfo: () => this.getUserInfo(),
      relogin:(params,funcSuccess,funcFail)=>{
        this.alert(Object.assign({
          alertType:'relogin',
          funcSuccess:funcSuccess,
          funcFail:funcFail,
        },params))
      },
      services: {
        getData: (base, filter, callback) => this.services('getData', base, filter, null, callback),
        count: (base, filter, callback) => this.services('count', base, filter, null, callback),
        getItem: (base, filter, callback) => this.services('getItem', base, filter, null, callback),
        saveData: (base, data, callback) => this.services('saveData', base, data, null, callback),
        aggregate: (base, data, callback) => this.services('aggregate', base, data, null, callback),
        saveItem: (base, item, callback) => this.services('saveItem', base, item, null, callback),
        distinct: (base, filter, field, callback) => this.services('distinct', base, { filter, field }, null, callback),
        external: (url, data, header, callback) => this.services('external', url, data, header, callback),
        internal: (endpoint, acesso, data, callback) => this.services('internal', endpoint, data, acesso, callback),
        getDataAsync: (base, filter) => new Promise(resolve => this.services('getData', base, filter, null, result => resolve(result))),
        countAsync: (base, filter) => new Promise(resolve => this.services('count', base, filter, null, result => resolve(result))),
        getItemAsync: (base, filter) => new Promise(resolve => this.services('getItem', base, filter, null, result => resolve(result))),
        saveDataAsync: (base, data) => new Promise(resolve => this.services('saveData', base, data, null, result => resolve(result))),
        aggregateAsync: (base, data) => new Promise(resolve => this.services('aggregate', base, data, null, result => resolve(result))),
        saveItemAsync: (base, item) => new Promise(resolve => this.services('saveItem', base, item, null, result => resolve(result))),
        distinctAsync: (base, filter, field) => new Promise(resolve => this.services('distinct', base, { filter, field }, null, result => resolve(result))),
        externalAsync: (url, data, header) => new Promise(resolve => this.services('external', url, data, header, result => resolve(result))),
        internalAsync: (endpoint, acesso, data) => new Promise(resolve => this.services('internal', endpoint, data, acesso, result => resolve(result)))
      }
    }

    this.tools[layout.pageType] = Object.assign(toolsBase, {
      itemNew: itemNew,
      load: { orig: {} },
      pageType: layout.pageType,
      runAsync: async (func, param1, param2) => { return await toolsBase.services[func](param1, param2) },
      getTools: (pageType) => {
        if (pageType) {
          return this.tools[pageType];
        } else {
          return {
            toolsGlobal: this.tools,
            pageTypeAtual: layout.pageType
          };
        }
      },
      scope: JSON.parse(JSON.stringify(layout.scope)),
      funcs: layout.funcs,
      params: layout.params,
      getComponent: (value, field?) => findRecursive(layout.components, (field || 'id'), value, ['elements', 'components', 'charts']),
      getChart: (id) => {
        return this.getChart(layout.components, id)
      },
      baseView: {
        model: (layout.components.find(e => e.baseView) || { model: 'view' }).model,
        scope: (layout.components.find(e => e.baseView) || { scope: 'item' }).scope || 'item'
      }
    });
    this.loadAll(layout.params.load, layout.pageType, layout.scope.item, callback);
  }

  shortcut(data) {
    this.mods.getMod(data.modId, data.mod)

    setTimeout(() => {
      this.cmdFunc(data.pageType, data.funcname, Object.assign(data.params, { shortcut: true }))
    }, 400)
  }

  nameParse(texto, name, itemScope) {

    let editado = false;

    var partsLang = texto.split('#{');
    if (partsLang.length > 1) {
      partsLang.shift();
      partsLang.forEach(e => {
        let variavelLang = e.split('}')[0];
        var textLang = this.lang.getText(variavelLang);
        texto = texto.replace('#{' + variavelLang + '}', textLang);
        editado = true;
      });
    }

    var parts = texto.split('${');
    if (parts.length > 1) {
      parts.shift();
      parts.forEach(e => {
        let model = e.split('}')[0];
        // console.log('model', model, itemScope);
        if (itemScope[model]) {
          texto = texto.replace('${' + model + '}', itemScope[model]);
          editado = true;
        }
      });
    }

    if (texto.split('@{name}').length > 1) {
      texto = texto.replace('@{name}', this.getUserInfo().name);
      editado = true;
    }

    if (editado == false && name && name != '') {
      return name;
    }
    return texto;
  }

  getChart(componentes, chartId) {
    let findComponents = (list, id) => {
      var dataResult = null;
      list.forEach(e => {

        if (e.id == id) { dataResult = e }
        if (e.charts) {
          let result = findComponents(e.charts, id);
          if (result) { dataResult = result; }
        }
      })
      return dataResult;
    };

    return findComponents(componentes, chartId);
  }

  async services(type, urlEndpoint, dataItemFilter, headerOrAcesso, callback) {
    // console.log("🚀 ~ dataItemFilter:", dataItemFilter)
    let dataFinal = dataItemFilter;
    if (type != 'external' && type != 'internal') {
      let user = this.getUserInfo()

      if (type == 'getData') { dataFinal = { base: urlEndpoint, filter: dataItemFilter }; }
      if (type == 'aggregate') { dataFinal = { base: urlEndpoint, pipe: dataItemFilter.pipe, options: dataItemFilter.options }; }
      if (type == 'getItem') { dataFinal = { base: urlEndpoint, _id: dataItemFilter._id }; }
      if (type == 'saveData') { dataFinal = { base: urlEndpoint, list: dataItemFilter, username: user.username, userId: user.userId }; }
      if (type == 'saveItem') { dataFinal = { base: urlEndpoint, item: dataItemFilter, username: user.username, userId: user.userId }; }
      if (type == 'distinct') { dataFinal = { base: urlEndpoint, filter: dataItemFilter.filter, field: dataItemFilter.field }; }
      if (type == 'count') { dataFinal = { base: urlEndpoint, filter: dataItemFilter }; }
      this.api.portal(type.toLowerCase(), dataFinal, (result) => callback(result.data));
    } else if (type == 'internal') {
      if (this.tokenSaveId != urlEndpoint || new Date().getTime() - this.tokenSaveTime > (1000 * 60 * 10)) {
        var postAsync = (postUrl, postData, postHeader?) => new Promise(resolve => this.api.post(postUrl, postData, (postResult) => resolve(postResult), postHeader))
        var urlToken = this.api.getUrl('auth');
        var resultToken: any = await postAsync(urlToken, headerOrAcesso, { client: headerOrAcesso.client });
        this.tokenSave = resultToken.token;
        this.tokenSaveId = urlEndpoint + '';
        this.tokenSaveTime = new Date().getTime();
      }
      var urlInternal = this.api.getUrl('internal') + (urlEndpoint + '');
      this.api.post(urlInternal, dataFinal, (result) => callback(result), { client: headerOrAcesso.client, 'x-access-token': this.tokenSave });
    } else {
      this.api.post(urlEndpoint, dataFinal, (result) => callback(result), headerOrAcesso);
    }
  }

  alert(data, callback?) {
    this.mods.updAlert(data, callback);
  }

  confirm(data) {
    this.cmdFunc(data.pageType, data.confirmOne, data.params)
  }

  confirmTwo(data) {
    this.cmdFunc(data.pageType, data.confirmTwo, data.params)
  }

  layout(data) {
    if (typeof data == 'string') {
      data = { pageId: data };
    }
    this.mods.layout(data);
  }

  cmdOptions(option, param?) {
    if (option == 'save') {
      let tools = this.tools[param.pageType];
      tools.scope[(param.scope || 'item')].ativo = true;
      this.services('saveItem', param.base, tools.scope[(param.scope || 'item')], null, (result) => {
        // alert('Salvo');
        this.mods.updAlert('Salvo');
      });
    }
    if (option == 'delete') {
      let tools = this.tools[param.pageType];
      tools.scope[(param.scope || 'item')].ativo = false;
      this.services('saveItem', param.base, tools.scope[(param.scope || 'item')], null, (result) => {
        // alert('Deletado');
        this.mods.updAlert('Deletado');
      });
    }
    if (option == 'deleteItem') {
      param.item.ativo = false;
      this.services('saveItem', param.base, param.item, null, (result) => {
        // alert('Deletado');
        this.mods.updAlert('Deletado');
        this.layout(param.pageId);
      });
    }
  }

  getTools(pageType) {
    return this.tools[pageType];
  }

  readView(base, pageType, scope) {
    let parseView = (data: any, scope) => {
      let scopeSel = (data.scope || scope);
      let item = this.getScope(pageType, scopeSel);
      if (!item) { return false; }
      if (data['$and']) { return data['$and'].every(e => parseView(e, scopeSel)); }
      if (data['$or']) { return data['$or'].some(e => parseView(e, scopeSel)); }
      let status = true;
      if (!data.value) {
        status = item[data.model];
      } else if (typeof data.value == 'string') {
        status = data.value == item[data.model];
      } else {
        status = data.value.indexOf(item[data.model]) >= 0;
      }
      return data.hide ? !status : status;
    }
    return parseView(base, scope);
  }

  checkView(view, pageType, scope, test?) {
    if (!view) { return true }
    if (typeof view == 'string') { view = { model: this.getBaseView(pageType).model, value: view, scope: this.getBaseView(pageType).scope }; }
    if (view.scope) { scope = view.scope; }
    if (!scope) { scope = 'item'; }
    let statusView = this.readView(view, pageType, scope);
    return statusView;
  }

  clearTools() {
    this.tools = {
      layer: {},
      modal: {}
    };
  }

  setLoad(pageType, table, viewRows, onlySearch?) {
    if (!onlySearch) {
      this.tools[pageType].load.orig[table] = [...viewRows];
    }
    this.tools[pageType].load[table] = [...viewRows];
    this.mods.updComponents({ pageType: pageType, type: 'load', table: table });
    setTimeout(() => {
      window.dispatchEvent(new Event('resize'));
    }, 200)
  }

  runFunc(funcname, comp, scope, param?) {
    this.setItem(comp.pageType, scope, comp.modelBind || comp.model, comp.value);
    if (typeof this.tools[comp.pageType].funcs[funcname] == 'function') {
      this.tools[comp.pageType].funcs[funcname](this.tools[comp.pageType], Object.assign(param || {}, {
        model: comp.model,
        value: comp.value
      }));
      this.setItem(comp.pageType, scope, comp.modelBind || comp.model, comp.value);
    }
  }

  cmdFunc(pageType, funcname, params?) {
    if (typeof this.tools[pageType].funcs[funcname] == 'function') {
      this.tools[pageType].funcs[funcname](this.tools[pageType], Object.assign(params || {}, {
        cmdFunc: true
      }));

      this.mods.updComponents({ pageType: pageType, type: 'scope' });
    }
  }

  async saveNotifications(data, callback) {
    // console.log("🚀 ~ saveNotifications, data: ", data)

    //GET NOTIFICAÇOES
    let dataFinalGet = { base: 'cadNotificacoes', filter: { id: data.id } }
    setTimeout(() => {
      this.api.portal('getData'.toLowerCase(), dataFinalGet, (result: any) => {
        // console.log("🚀 ~ result getData, notificaçao existente?", result.data)
        if (result.data.length > 0) {
          return console.log('not existente');
        }

        //SAVE NOTIFICAÇÃO
        let dataFinal = { base: 'cadNotificacoes', item: data }
        this.api.portal('saveItem'.toLowerCase(), dataFinal, (resultSaveNot: any) => {
        });
      });
    }, Math.floor(Math.random() * 3000))


  }

  async getNotificationsCad(cad, filter, callback) {
    let dataFinal = { base: cad, filter: filter }
    this.api.portal('getData'.toLowerCase(), dataFinal, (result: any) => callback(result.data));
  }

  dataForm(lista) {
    for (var x1 in lista) {
      lista[x1] = this.itemForm(lista[x1]);
    }
    return lista;
  }

  itemForm(item) {
    var obj = {};
    var reload = false;
    for (var campo in item) {
      if (typeof item[campo] == 'object' && !Array.isArray(item[campo])) {
        for (var subcampo in item[campo]) {
          obj[campo + '.' + subcampo] = item[campo][subcampo];
          if (typeof obj[campo + '.' + subcampo] == 'object' && !Array.isArray(obj[campo + '.' + subcampo])) {
            reload = true;
          }
        }
      } else {
        obj[campo] = item[campo];
      }
    }
    if (reload) {
      return this.itemForm(obj);
    } else {
      return obj;
    }
  }

  public setItem(pageType, scope, model, value) {
    var parts = model.split('.');//['dados','nomes','apelido']
    var obj = this.tools[pageType].scope[scope];
    if (parts.length > 1) { if (!obj[parts[0]]) { obj[parts[0]] = {}; } }
    if (parts.length > 2) { if (!obj[parts[0]][parts[1]]) { obj[parts[0]][parts[1]] = {}; } }
    if (parts.length > 3) { if (!obj[parts[0]][parts[1]][parts[2]]) { obj[parts[0]][parts[1]][parts[2]] = {}; } }
    if (parts.length > 4) { if (!obj[parts[0]][parts[1]][parts[2]][parts[3]]) { obj[parts[0]][parts[1]][parts[2]][parts[3]] = {}; } }
    if (parts.length > 5) { if (!obj[parts[0]][parts[1]][parts[2]][parts[3]][parts[4]]) { obj[parts[0]][parts[1]][parts[2]][parts[3]][parts[4]] = {}; } }
    if (parts.length > 6) { if (!obj[parts[0]][parts[1]][parts[2]][parts[3]][parts[4]][parts[5]]) { obj[parts[0]][parts[1]][parts[2]][parts[3]][parts[4]][parts[5]] = {}; } }
    if (parts.length > 7) { if (!obj[parts[0]][parts[1]][parts[2]][parts[3]][parts[4]][parts[5]][parts[6]]) { obj[parts[0]][parts[1]][parts[2]][parts[3]][parts[4]][parts[5]][parts[6]] = {}; } }
    if (parts.length > 8) { if (!obj[parts[0]][parts[1]][parts[2]][parts[3]][parts[4]][parts[5]][parts[6]][parts[7]]) { obj[parts[0]][parts[1]][parts[2]][parts[3]][parts[4]][parts[5]][parts[6]][parts[7]] = {}; } }
    if (parts.length == 1) { obj[model] = value; }
    if (parts.length == 2) { obj[parts[0]][parts[1]] = value; }
    if (parts.length == 3) { obj[parts[0]][parts[1]][parts[2]] = value; }
    if (parts.length == 4) { obj[parts[0]][parts[1]][parts[2]][parts[3]] = value; }
    if (parts.length == 5) { obj[parts[0]][parts[1]][parts[2]][parts[3]][parts[4]] = value; }
    if (parts.length == 6) { obj[parts[0]][parts[1]][parts[2]][parts[3]][parts[4]][parts[5]] = value; }
    if (parts.length == 7) { obj[parts[0]][parts[1]][parts[2]][parts[3]][parts[4]][parts[5]][parts[6]] = value; }
    if (parts.length == 8) { obj[parts[0]][parts[1]][parts[2]][parts[3]][parts[4]][parts[5]][parts[6]][parts[7]] = value; }
    if (parts.length == 9) { obj[parts[0]][parts[1]][parts[2]][parts[3]][parts[4]][parts[5]][parts[6]][parts[7]][parts[8]] = value; }
    this.tools[pageType].scope[scope] = obj;
    this.mods.updComponents({ pageType: pageType, type: 'scope' });
  }

  getBaseView(pageType) {
    return this.tools[pageType].baseView;
  }

  getScope(pageType, scope) {
    if (!this.tools[pageType]) { return null }
    return this.tools[pageType].scope[scope];
  }

  getLoad(pageType, table) {
    return this.tools[pageType].load[table];
  }

  getLoadOrig(pageType, table) {
    return this.tools[pageType].load.orig[table];
  }

  itemObj(eItem) {
    var obj = {};
    for (var campo in eItem) {
      if (typeof eItem[campo] == 'string' || typeof eItem[campo] == 'number' || typeof eItem[campo] == 'boolean' || Array.isArray(eItem[campo])) {
        var parts = campo.split('.');
        if (parts.length > 1) { if (!obj[parts[0]]) { obj[parts[0]] = {}; } }
        if (parts.length > 2) { if (!obj[parts[0]][parts[1]]) { obj[parts[0]][parts[1]] = {}; } }
        if (parts.length > 3) { if (!obj[parts[0]][parts[1]][parts[2]]) { obj[parts[0]][parts[1]][parts[2]] = {}; } }
        if (parts.length > 4) { if (!obj[parts[0]][parts[1]][parts[2]][parts[3]]) { obj[parts[0]][parts[1]][parts[2]][parts[3]] = {}; } }
        if (parts.length > 5) { if (!obj[parts[0]][parts[1]][parts[2]][parts[3]][parts[4]]) { obj[parts[0]][parts[1]][parts[2]][parts[3]][parts[4]] = {}; } }
        if (parts.length > 6) { if (!obj[parts[0]][parts[1]][parts[2]][parts[3]][parts[4]][parts[5]]) { obj[parts[0]][parts[1]][parts[2]][parts[3]][parts[4]][parts[5]] = {}; } }
        if (parts.length > 7) { if (!obj[parts[0]][parts[1]][parts[2]][parts[3]][parts[4]][parts[5]][parts[6]]) { obj[parts[0]][parts[1]][parts[2]][parts[3]][parts[4]][parts[5]][parts[6]] = {}; } }
        if (parts.length > 8) { if (!obj[parts[0]][parts[1]][parts[2]][parts[3]][parts[4]][parts[5]][parts[6]][parts[7]]) { obj[parts[0]][parts[1]][parts[2]][parts[3]][parts[4]][parts[5]][parts[6]][parts[7]] = {}; } }
        if (parts.length == 1) { obj[campo] = eItem[campo]; }
        if (parts.length == 2) { obj[parts[0]][parts[1]] = eItem[campo]; }
        if (parts.length == 3) { obj[parts[0]][parts[1]][parts[2]] = eItem[campo]; }
        if (parts.length == 4) { obj[parts[0]][parts[1]][parts[2]][parts[3]] = eItem[campo]; }
        if (parts.length == 5) { obj[parts[0]][parts[1]][parts[2]][parts[3]][parts[4]] = eItem[campo]; }
        if (parts.length == 6) { obj[parts[0]][parts[1]][parts[2]][parts[3]][parts[4]][parts[5]] = eItem[campo]; }
        if (parts.length == 7) { obj[parts[0]][parts[1]][parts[2]][parts[3]][parts[4]][parts[5]][parts[6]] = eItem[campo]; }
        if (parts.length == 8) { obj[parts[0]][parts[1]][parts[2]][parts[3]][parts[4]][parts[5]][parts[6]][parts[7]] = eItem[campo]; }
        if (parts.length == 9) { obj[parts[0]][parts[1]][parts[2]][parts[3]][parts[4]][parts[5]][parts[6]][parts[7]][parts[8]] = eItem[campo]; }
      } else {
        obj[campo] = eItem[campo];
      }
    }
    return obj
  }

  load(item, itemScope) {
    return new Promise(resolve => {
      if (item.filterFunc) {
        if(item.filterFunc.length>1){
          let user = this.getUserInfo()
          item.filter = item.filterFunc(itemScope,user);
        }else{
          item.filter = item.filterFunc(itemScope);
        }
      }
      if (item.type == 'base') {
        this.api.portal('getdata', { base: item.base, filter: (item.filter || { ativo: true }), sort: item.sort }, (result) => {
          resolve(result.data);
        });
      }
      if (item.type == 'data') {
        resolve([...item.data].map(e => { e.select = false; return e }))
      }
      if (item.type == 'item') {

        resolve([...itemScope[item.item]].map(e => { e.select = false; return e }))
      }
    });
  }

  search(pageType, table, searchValue, selectedFilters?) {
    let filterFunc = (item, value) => value.toLowerCase().split(' ').every(g => Object.values(item).filter(f => typeof f == 'string').some((f: any) => f.toLowerCase().indexOf(g) !== -1 || !g))
    let filterTags = (e, tags) => tags.every(g => g.values.indexOf(e[g.field]) >= 0);
    let listFilter = this.getLoadOrig(pageType, table);
    if (selectedFilters && selectedFilters.length > 0) { listFilter = listFilter.filter(e => filterTags(e, selectedFilters)); }
    if (searchValue != '') { listFilter = listFilter.filter(e => filterFunc(e, searchValue)) }
    this.setLoad(pageType, table, listFilter, true);
    return listFilter;
  }

  loadItem(optItem: any, defaultItem) {
    return new Promise(resolve => {
      if (!optItem || !optItem.itemId || !optItem.base) {
        if (optItem) {
          resolve(Object.assign(defaultItem, optItem));
        } else {
          resolve(Object.assign({}, defaultItem));
        }
      } else {
        this.api.portal('getitem', { base: optItem.base, _id: optItem.itemId }, (result) => {
          resolve(Object.assign(defaultItem, result.data));
        });
      }
    });
  }

  async loadAll(load, pageType, itemScope, callback) {
    
    for (var itemLoad of load) {
      let listLoad: any = await this.load(itemLoad, itemScope);
      if(itemLoad.trat){
        if(itemLoad.trat.length>1){
          let user = this.getUserInfo()
          listLoad = itemLoad.trat(listLoad,user);
        }else{
          listLoad = itemLoad.trat(listLoad);
        }
      }
      this.tools[pageType].load[itemLoad.model] = listLoad;
      this.tools[pageType].load.orig[itemLoad.model] = [...listLoad];

    }
    callback(this.tools[pageType])
  }

  excel(data: any, cols: any, ReportTitle: any, callback: any) {
    var records: any = [[]];
    var fields = [];

    for (var x1 in cols) {
      if (cols[x1].dataType || cols[x1].hide) { continue; }
      records[0].push(cols[x1].name);
      let campo = cols[x1].field || cols[x1].prop;
      fields.push(campo);
    }

    for (var x1 in data) {
      let number: any = x1;
      records[(number * 1) + 1] = [];

      for (var fi in fields) {
        var fivalue = '';
        if (data[x1][fields[fi]] && data[x1][fields[fi]] != null) {
          fivalue = data[x1][fields[fi]];
        }
        records[(number * 1) + 1].push(fivalue);
      }
    }

    this.xlsx(records, ReportTitle, ReportTitle, callback)
  }

  pdf(rows, cols, title, callback) {
    if (rows.length == 0) { return alert('Sem dados na tabela'); }
    cols = cols.filter(e => !e.dataType && !e.hide);

    if (cols.length > 6) {
      var x: any = prompt('Quantas colunas deseja imprimir ?', cols.length);
      if (!x || isNaN(x)) return
      // alert('Atenção, relatório limitado à 6 colunas, mostrando as 6 primeiras');
      cols = cols.slice(0, x);
    }

    var head = cols.map(e => e.name);
    var body = rows.map(e => cols.map(f => e[f.prop]));

    // L = Retrato | P = Paisagem
    const orientacao = cols.length > 6 ? 'l' : 'p';

    const doc = new jspdf.jsPDF(orientacao, 'mm');

    let color = this.api.getClientFull()?.tema?.primary || '#09242c';
    // console.log("🚀 -> this.api.getClientFull():", this.api.getClientFull());

    var totalPagesExp = '{total_pages_count_string}';

    doc.autoTable({
      margin: { top: 35 },
      head: [head],
      body: body,
      theme: 'striped',
      headStyles: { fillColor: color },
      didDrawPage: (data) => {
        // Cabeçalho
        const image = document.createElement('img')
        image.setAttribute('src', `../../${this.api.getClientFull()?.tema?.logo || '../../logo_black.png'}`);
        doc.addImage(image, 'PNG', 15, 5, 60, 15);

        doc.setFontSize(15);
        doc.text(title, 15, 30);

        doc.setFontSize(10);
        doc.text(`Usuario: ${this.userInfo.username}`, orientacao == 'p' ? 140 : 220, 15);
        doc.text(`Data: ${new Date().toLocaleString('pt-BR')}`, orientacao == 'p' ? 140 : 220, 20);

        var str = `Página ${doc.internal.getNumberOfPages()}`;

        if (typeof doc.putTotalPages === 'function') str += ` de ${totalPagesExp}`;

        doc.setFontSize(10);

        // var pageSize = doc.internal.pageSize;
        // var pageHeight = pageSize.height ? pageSize.height : pageSize.getHeight();
        // doc.text(str, data.settings.margin.left, pageHeight - 10);

        doc.text(str, orientacao == 'p' ? 140 : 220, 25);
        doc.text(location.origin, orientacao == 'p' ? 140 : 220, 30);
      }
    })

    if (typeof doc.putTotalPages === 'function') doc.putTotalPages(totalPagesExp)

    doc.save(title.replaceAll(' ', '_') + `-${new Date().toLocaleDateString('pt-BR')}.pdf`);

    callback({ status: true, filename: title + ".pdf" })
  }

  xlsx(JSONData: any, ReportTitle: any, title: any, callback: any) {
    function createSheet(data) {
      function datenum(v, date1904) {
        if (date1904) v += 1462;
        var epoch: any = Date.parse(v);
        var utc: any = Date.UTC(1899, 11, 30);
        var newdate: any = new Date(utc)
        return (epoch - newdate) / (24 * 60 * 60 * 1000);
      }
      var ws = {};
      var range = { s: { c: 10000000, r: 10000000 }, e: { c: 0, r: 0 } };
      for (var R = 0; R != data.length; ++R) {
        for (var C = 0; C != data[R].length; ++C) {
          if (range.s.r > R) range.s.r = R;
          if (range.s.c > C) range.s.c = C;
          if (range.e.r < R) range.e.r = R;
          if (range.e.c < C) range.e.c = C;
          var cell: any = { v: data[R][C] };
          if (cell.v == null) continue;
          var cell_ref = XLSX.utils.encode_cell({ c: C, r: R });
          if (typeof cell.v === 'number') cell.t = 'n';
          else if (typeof cell.v === 'boolean') cell.t = 'b';
          else if (cell.v instanceof Date) {
            cell.t = 'n'; cell.z = XLSX.SSF._table[14];
            cell.v = datenum(cell.v, false);
          }
          else cell.t = 's';
          ws[cell_ref] = cell;
        }
      }
      if (range.s.c < 10000000) ws['!ref'] = XLSX.utils.encode_range(range);
      return ws;
    }
    let Workbook = (data?, name?) => {
      var wbase: any = {};
      wbase.SheetNames = [name];
      wbase.Sheets = {};
      for (var x1 in wbase.SheetNames) { wbase.Sheets[wbase.SheetNames[x1]] = createSheet(Array.isArray(name) ? data[x1] : [data][x1]); }

      return wbase
    }

    function s2ab(s) {
      var buf = new ArrayBuffer(s.length);
      var view = new Uint8Array(buf);
      for (var i = 0; i != s.length; ++i) view[i] = s.charCodeAt(i) & 0xFF;
      return buf;
    }

    var wb = Workbook(JSONData, ReportTitle);

    var wbout = XLSX.write(wb, { bookType: 'xlsx', bookSST: true, type: 'binary' });
    var blob = new Blob([s2ab(wbout)], { type: "application/octet-stream" });

    var fileSave = saveAs(blob, title.replaceAll(' ', '_') + `-${new Date().toLocaleDateString('pt-BR')}.xlsx`);

    fileSave.onwriteend = () => setTimeout(() => callback({ status: true, filename: title + ".xlsx" }), 250);
  }

  setUserInfo(data) {
    this.userInfo = data
  }

  getUserInfo() {
    return this.userInfo;
  }

  async interactionAI(interaction: string, request: string, countChat: number) {
    return new Promise(resolve => {
      let user = this.getUserInfo()

      //AUTH
      // let urlAuth = 'https://chama-qa.applayos.com:5099/auth'
      let urlAuth = 'https://ai.applayos.com:5099/auth'
      let dataAuth = {
        // "app_key": "8wOnGiEejn1cpyy3fiGchv23",
        // "app_secret": "pIRvqab4iP9X",
        "app_key": "KjsocrhLZ9kf1BC6GnEuvREZ",
        "app_secret": "sPQ8tKleX0Mn"
      }

      this.api.post(urlAuth, dataAuth, (resAuth) => {
        //SEND REQUEST AI
        // let url = 'https://chama-qa.applayos.com:5099/api2/applayAI/vertex/conversation'
        // let url = 'https://ai.applayos.com:5099/api2/applayAI/vertex/conversation'
        let url = 'https://ai.applayos.com:5099/api2/applayAI/vertex/'+interaction
        let data = {
          "username": user.username,
          "message": request,
          "chatId": user.session + (countChat + ''),
          "model": "gemini-1.5-flash-001",
          "pretty": true,
          "save": true
        }
        let headers = {
          'x-access-token': resAuth.token
        }

        this.api.post(url, data, (resSendRequest) => {
          // console.log("🚀 ~ resSendRequest:", resSendRequest)

          resolve(resSendRequest)
        }, headers)
      })
    })
  }

  async aggregateAsync(base: string, data: any) {
    return new Promise(resolve => {
      this.services('aggregate', base, data, {}, (result)=>{resolve(result)})
    })
  }
}