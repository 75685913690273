import { Component, OnInit, Input, OnChanges, DoCheck } from '@angular/core';
import { ToolsService } from '../../services/tools.service';

@Component({
  selector: 'app-tabledash',
  templateUrl: './tabledash.component.html',
  styleUrls: ['./tabledash.component.css']
})
export class TabledashComponent implements OnInit, OnChanges, DoCheck {

  @Input() public comp: any;
  headers: any = []
  list: any = []
  listTemp: any = []

  constructor(private tools: ToolsService) { }

  ngOnInit(): void {
  }

  ngOnChanges() {
  }

  ngDoCheck() {
    // console.log("🚀 ~ ngDoCheck")
    if(this.comp.data && this.comp.data.length > 0) {
      this.list = this.comp.data
      this.headers = this.comp.header

      this.listTemp = this.comp.data
    }
  }

  click(row){
    if(this.comp.click && this.comp.click != '') {
      this.tools.cmdFunc('layer', this.comp.click, row)
    }
  }

}
