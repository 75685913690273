import { Component, OnInit, ElementRef, HostListener } from '@angular/core';
import { Subscription } from 'rxjs';
import { UserService } from '../../services/user.service';
import { ModsService } from '../../services/mods.service';
import { SyncService } from '../../services/sync.service';
import { LangService } from '../../services/lang.service';

@Component({
  selector: 'app-menu',
  templateUrl: './menu.component.html',
  styleUrls: ['./menu.component.css']
})
export class MenuComponent implements OnInit {

  subscription: Subscription;
  menu: any = [];

  constructor(
    private user: UserService,
    private mods: ModsService,
    private sync: SyncService,
    private lang: LangService,
    private eRef: ElementRef
  ) {
    this.menu = [];
  }

  @HostListener('document:click', ['$event'])
  clickout(event) {
    if (!this.eRef.nativeElement.contains(event.target)) {
      this.clear()
    }
  }

  processLang(menu) {
    let lang = this.lang.getLang().lang
    for (var cat of menu) {
      cat.nome = cat.lang?.[lang] || cat.nome;
      for (var mod of cat.list) {
        mod.name = mod.lang?.[lang] || mod.name;
      }
    }
    return menu;
  }

  ngOnInit(): void {
    this.user.getMenu((result) => {
    console.log("🚀 ~ result:", result)

      this.menu = this.processLang(result.data);
      console.log(this.menu);

      this.click(this.menu[0].list[0], this.menu[0].name);
    })

    this.subscription = this.sync.listenSync.subscribe(data => {
      if (data.type == 'menu') {
        this.menu = this.processLang(this.menu);
      }
    });
  }

  clickMenu(menuItem) {
    if (menuItem.showList) {
      menuItem.showList = false;
    } else {
      this.menu.forEach(e => e.showList = false);
      menuItem.showList = true;
    }
  }

  clear(menuItem?) {
    if (menuItem && menuItem.showList) {
      this.menu.forEach(e => e.showList = false);
      menuItem.showList = true;
    } else {
      this.menu.forEach(e => e.showList = false);
    }
  }


  click(item, systemName) {
    if (item.click) {
      this.clear();
      this.mods.getMod(item.click, systemName);
    } else {
      item.showList = !item.showList;
    }
  }

}
