<div class="content">
  <div class="content-search"></div>

  <div class="content-table">
    <table>
      <thead>
        <tr>
          <th *ngFor="let header of headers">
            <i *ngIf="header.icon" [ngClass]="'fa fa-'+(header.icon)"></i>
            {{header.name}}
          </th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let row of list" (click)="click(row)">
          <td *ngFor="let item of headers">
            <!-- {{row.url | json}} -->
            <!-- {{row[item.field] | json}} -->

            <div class="content-img" *ngIf="item.dataType=='url'">
              <img class="img" [src]="row.url">
            </div>
            <div class="content-row">
              <div class="row-dash" *ngIf="item.dataType=='dash'">
                <div class="dash" *ngFor="let dash of item.data">
                  <div class="name" *ngIf="dash.name && row[item.field][dash.name]" >{{row[item.field][dash.name]}}</div>
                  <i *ngIf="dash.icon && row[item.field][item.idx][dash.field]" [ngStyle]="{'color': row[item.field][item.idx].color?row[item.field][item.idx].color: 'black'}" [ngClass]="'fa fa-'+(dash.icon)"></i>
                  <div class="name" *ngIf="dash.field" >{{row[item.field][item.idx][dash.field]}}</div>
                </div>
              </div>
            </div>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</div>